import React, { useState } from 'react'
import { supportFormatAvif } from '@/shared/utils/supportFormatAvif'
import { useEffect } from 'react'

import styles from './styles'

interface IProps {
    animationType: 'thumbs_up_full' | 'happy' | 'hiding' | 'sad' | 'relived' | 'confused' | 'thumbs_up_side';
    runAdditionalHiddingAnimation?: boolean;
    autoPlay?: boolean;
    backgroundSrc?: string;
    errorPreset?: boolean;
    mods?: string;
}

import applyMods from '@/shared/utils/applyMods'

export const THUMBS_UP_FULL_MASCOT = 'thumbs_up_full'
export const THUMBS_UP_SIDE_MASCOT = 'thumbs_up_side'
export const HAPPY_MASCOT = 'happy'
export const HIDING_MASCOT = 'hiding'
export const SAD_MASCOT = 'sad'
export const RELIVED_MASCOT = 'relived'
export const CONFUSED_MASCOT = 'confused'


export function Animation({
    autoPlay = true, 
    animationType,
    runAdditionalHiddingAnimation = false,
    backgroundSrc = '',
    errorPreset = false,
    mods = ''
}: IProps) {

    const animationsBaseUrl = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_ANIMATIONS}`
    const animationImagesPath = `${animationsBaseUrl}/${animationType}/images/`
    const animationDataPath = `${animationsBaseUrl}/${animationType}/data.json`
    const classes = styles()
    const forceFlag = true // boolean for forcing the new segment right away
    const [animation, setAnimation] = useState(null)

    const launchAnimation = async (animationDataPath: string) => {

        const response = await fetch(animationDataPath)
        const animationData = await response.json()

        const lottieAnimation = await loadAnimation(animationData)

        setAnimation(lottieAnimation)

    }

    useEffect(() => {
        launchAnimation(animationDataPath)
    }, [])

    const loadAnimation = async (animationData) => {
        const elementId = `lottie-${animationType}`
        const element = document.getElementById(elementId)

        const isAvifFormatSupport = supportFormatAvif()
        if (isAvifFormatSupport) {
            const regex = /.png/gi
            animationData.assets.forEach((asset) => {
                const imageNamePng = asset.p
                const imageNameAvif = imageNamePng.replace(regex, '.avif')
                asset.p = imageNameAvif
            })
        }

        animationData.assets.forEach((asset) => {
            asset.u = animationImagesPath
        })

        const lottie = (await import('lottie-web/build/player/lottie_light')).default

        const lottieAnimation = lottie.loadAnimation({
            container: element,
            renderer: isAvifFormatSupport ? 'canvas' : 'svg',
            loop: autoPlay,
            autoplay: autoPlay,
            animationData: animationData,
            rendererSettings: {
                viewBoxOnly: true,
                imagePreserveAspectRatio: 'xMidYMid meet'
            }
        })

        return lottieAnimation

    }

    useEffect(() => {
        if (animationType === HIDING_MASCOT && animation !== null) {
            animation.setSubframe(true)
            keepOnFlying()
        }
    }, [animation])

    function keepOnFlying(direction = 1): void {
        let newDirection = direction
        let segments

        if (newDirection === 1) {
            newDirection = -1
            segments = [22,0]
        } else {
            newDirection = 1
            segments = [0,22]
        }

        animation.removeEventListener('complete')
        animation.addEventListener('complete', () => keepOnFlying(newDirection))
        animation.setDirection(newDirection)
        animation.playSegments(segments, forceFlag)
    }

    function stopHiding(): void {
        const segments = [60,34]
        animation.setDirection(-1)
        animation.playSegments(segments, forceFlag)
        animation.addEventListener('complete', keepOnFlying)
    }

    function keepOnHiding(): void {
        const segments = [60,91]
        animation.playSegments(segments, forceFlag)
    }

    function startHiding(): void {
        const segments = [34,60]
        animation.removeEventListener('complete')
        animation.playSegments(segments, forceFlag)
        animation.addEventListener('complete', keepOnHiding)
    }

    return (
        <div
            className={`${applyMods(classes.root, mods)}${errorPreset ? ' error-preset' : ''}`}
            style={{backgroundImage: backgroundSrc? `url(${backgroundSrc})` : ''}}
        >
            <div className={classes.inner} id={`lottie-${animationType}`}></div>
        </div>
    )
}

