import React from 'react'
import { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import applyMods from '@/shared/utils/applyMods'
import { useStyles } from './styles'

interface IButtonProps extends ButtonProps {
    type?: 'button' | 'submit' | 'reset';
    onClick?: (any) => unknown | void;
    mods?: string; // button modifications ex.: green, blue, cta, icon, active
    loading?: boolean;
}


export default function Button(props: IButtonProps ): React.ReactElement {
    const {
        children,
        type = 'button',
        mods = '',
        loading = false,
        ...rest
    } = props
    const classes = useStyles()

    return (
        <button
            type={type}
            className={applyMods(classes.root, `${mods}${mods && loading ? '|' : ''}${loading ? 'loading' : ''}`) }
            {...rest}
        >
            {loading && <div className={classes.loader}><CircularProgress size={20} color='inherit' thickness={5} /></div>}
            {children}
        </button>
    )
}