import { makeStyles } from '@material-ui/core';
import { Colors } from '@/shared/styles/MuiTheme'

export default makeStyles({
  root: {
    background: '#eee',
    textAlign: 'center',
    height: '100vh',
    overflowY: 'hidden',
    '&--404, &--403': {
      background: Colors.GrayBackground,
      // '& .s50-error__message': {
         
      // }
    },
  },
  message: {
    fontWeight: 600,
    '& h2': {
      fontSize: '2.6rem'
    }
  },
  svg: {
    '& img': {
      height: '100%',
      maxWidth: '50%',
      paddingBottom: '50px'
    }
  },
  link: {
    color: '#222'
  },
  linkWrapper: {
    margin: '0 0 5rem',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
      padding: '5px'
    }
  },
})