import React from 'react'
import Head from 'next/head'
import { FormattedMessage, useIntl } from 'react-intl'
import MainLayout from 'components/Layout/MainLayout'
import { ErrorProps } from 'next/error'
import { Animation, CONFUSED_MASCOT } from '@/shared/components/Animation/Animation'
import ErrorFooter from './ErrorFooter'
import { errorMessages, websitesErrorMessage } from './const'
import styles from './styles'
import applyMods from '@/shared/utils/applyMods'
import ErrorLinkToGoBack from './ErrorLinkToGoBack'
import ErrorLinkToLogin from './ErrorLinkToLogin'
import ErrorLinkRetry from './ErrorLinkRetry'
import WebisteErrorPage from './WebsiteErrorPage'
import { useClub } from 'contexts/club'

function Error(props: ErrorProps): JSX.Element {
    const classes = styles()
    const { statusCode, title } = props

    const isGeneralError = ![401,404,403,504].includes(statusCode)
    const intl = useIntl()
    const { club, website } = useClub()

    if([401, 403].includes(statusCode)) {
        return (
            <WebisteErrorPage
                pageTitle={ website?.title ?? '' }
                message={intl.formatMessage(websitesErrorMessage['error.not_enough_rights.description'])}
                title={`${intl.formatMessage(websitesErrorMessage['error.not_enough_rights.title.1'])} ${ club?.title ?? '' } ${intl.formatMessage(websitesErrorMessage['error.not_enough_rights.title.2'])}`}
            />
        )
    }
    return (
        <MainLayout>
            <Head>
                <title>{title}</title>
            </Head>
            <div className={applyMods(classes.root, '404')}>
                <span className='fas fa-error'></span>
                <div className={classes.message}>
                    {statusCode === 401 && (
                        <>
                            <h2>
                                <b><FormattedMessage {...errorMessages['error.unauthorized']} /></b>
                            </h2>
                            <p className={'mb-0'}>
                                <FormattedMessage {...errorMessages['error.no_access']} />
                            </p>
                            <p>
                                <FormattedMessage {...errorMessages['error.try_to_login']} />
                            </p>
                        </>
                    )}
                    {statusCode === 404 && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <h2>
                                <b><FormattedMessage {...errorMessages['error.sorry']} /></b>,
                                {' '}
                                <span style={{ fontWeight: 500 }}><FormattedMessage {...errorMessages['error.not_found']} />...</span>
                            </h2>
                        </div>
                    )}
                    {statusCode === 403 && (
                        <>
                            <h2>
                                <b><FormattedMessage {...errorMessages['error.forbidden']} /></b>
                            </h2>
                            <p>
                                <FormattedMessage {...errorMessages['error.accessForbidden']} />
                            </p>
                        </>
                    )}
                    {statusCode === 504 && (
                        <>
                            <h2>
                                <FormattedMessage id='error.slowpage' defaultMessage='This page is taking way too long to load.' />
                            </h2>
                            <p>
                                <FormattedMessage id='error.sorry' defaultMessage='Sorry about that. Please try refreshing and contact us if the problem persists.' />
                            </p>
                        </>
                    )}
                    {isGeneralError && (
                        <>
                            <p>
                            <b><FormattedMessage {...errorMessages['error.something_wrong']} /></b>
                            </p>
                            <p className={'font-weight-light'}>
                                <FormattedMessage {...errorMessages['error.try_to_refresh']} />
                            </p>
                        </>
                    )}
                </div>
                <div className={classes.svg}>
                    <Animation
                        backgroundSrc={isGeneralError ? '' : `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/errors/${statusCode}.svg`}
                        errorPreset
                        animationType={CONFUSED_MASCOT}
                    />
                </div>
                <br />
                <div className={classes.linkWrapper}>
                    {[403, 404].includes(statusCode) && (
                        <ErrorLinkToGoBack />
                    )}
                    {statusCode === 401 && (
                        <ErrorLinkToLogin/>
                    )}
                    {isGeneralError && (
                        <ErrorLinkRetry/>
                    )}
                </div>
                <ErrorFooter />
            </div>
        </MainLayout>
    )
}

export default Error