import { defineMessages } from 'react-intl'

export const errorMessages = defineMessages({
    'error.btn.login': {
        id: 'error.btn.login',
        defaultMessage: 'Login'
    },
    'error.notFound': {
        id: 'error.notFound',
        defaultMessage: '404 Not found'
    },
    'error.no_access': {
        id: 'error.no_access',
        defaultMessage: 'You don\'t have access to this page.'
    },
    'error.try_to_login': {
        id: 'error.try_to_login',
        defaultMessage: 'Try to login again and contact us if the problem persists.'
    },
    'error.unauthorized': {
        id: 'error.unauthorized',
        defaultMessage: '401 Unauthorized'
    },
    'error.forbidden': {
        id: 'error.forbidden',
        defaultMessage: '403 Forbidden'
    },
    'error.accessForbidden': {
        id: 'error.accessForbidden',
        defaultMessage: 'You are not allowed to see this resource, contact the admin of your club.'
    },
    'error.btn.retry': {
        id: 'error.btn.retry',
        defaultMessage: 'Retry'
    },
    'error.something_wrong': {
        id: 'error.something_wrong',
        defaultMessage: 'Something went wrong'
    },
    'error.try_to_refresh': {
        id: 'error.try_to_refresh',
        defaultMessage: 'Sorry about that. Please try refreshing and contact us if the problem persists'
    },
    'error.not_found': {
        id: 'error.not_found',
        defaultMessage: 'page not found'
    },
    'error.sorry': {
        id: 'error.message.sorry',
        defaultMessage: 'Sorry'
    },
    'error.go_back': {
        id: 'error.go_back',
        defaultMessage: 'Go back'
    },
})

export const websitesErrorMessage = defineMessages({
    'error.not_enough_rights.title.1': {
        id: 'v4_website.error.not_enough_rights.title.1',
        defaultMessage: '###',
        // defaultMessage: 'Unfortunately, your don’t have viewing rights for this'
    },
    'error.not_enough_rights.title.2': {
        id: 'v4_website.error.not_enough_rights.title.2',
        defaultMessage: '###',
        // defaultMessage: 'page.'
    },
    'error.not_enough_rights.description': {
        id: 'v4_website.error.not_enough_rights.description',
        defaultMessage: '###',
        // defaultMessage: 'Contact your club administrator to gain access to this page.'
    },
})