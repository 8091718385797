import Link from 'next/link'
import React from 'react'
import styles from './styles'

const ErrorFooter = (): JSX.Element => {
    const classes = styles()
    return (
        <div className={classes.footerContainer}>
            <div>
                <div className='s50-error__logo text-right'>
                    <Link href='' passHref>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img className='s50-error__logo-image' height='30' src={`${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/clubee-logo/logo_black.svg`} alt='Clubee' />
                    </Link>
                </div>
            </div>
            <div>
                <span className='fas fa-envelope'></span>&nbsp;
                <div className={classes.link}>
                    <Link href='mailto:info@clubee.com'>
                        info@clubee.com
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ErrorFooter