import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  inner: {
    width: '100%',
    '& svg': {
        width: '100px',
        transform: 'translate3d(0px, 0px, 0px)',
        margin: '0 auto',
        display: 'block'
    }
  },
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    '& #lottie-thumbs_up_full': {
        '& svg': {
            width: '120px'
        }
    },

    '& #lottie-thumbs_up_side': {
        paddingBottom: '20px',
        '& svg': {
            width: 'auto',
        }
    },

    '& #lottie-happy': {
        '& svg': {
            width: '150px'
        }
    },

    '& #lottie-hiding': {
        '& svg': {
            width: '150px'
        }
    },

    '& #lottie-sad': {
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1,
        height: '190px',
        '& svg': {
            width: '150px',
            marginTop: '-20px'
        }
    },

    '& #lottie-relived': {
        '& svg': {
            width: '150px'
        }
    },

    '& #lottie-confused': {
        '& svg': {
            width: '150px'
        }
    },

    '&.error-preset': {
        '& svg': {
            width: '26% !important',
            maxWidth: '300px'
        }
    },

    '&--w-200': {
        '#lottie-sad, #lottie-confused': {
            '& svg': {
                width: '200px'
            }
        },
    }
  }
}))