import ExternalLink from '@/shared/components/ExternalLink/ExternalLink'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { errorMessages } from './const'

const ErrorLinkToLogin = (): JSX.Element => {
    return (
        <ExternalLink isClubUrl mods='green|cta' href='/user/login' isButton>
            <i className='far fa-user'></i>&nbsp;
            <FormattedMessage {...errorMessages['error.btn.login']} />
        </ExternalLink>
    )
}

export default ErrorLinkToLogin