import Button from '@/shared/components/Button/Button'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { errorMessages } from './const'

export default function ErrorLinkRetry(): JSX.Element {
    return (
        <div className='s50-error__link-to-go-back'>
            <Button mods='green|cta' type='button' onClick={() => location.reload()}>
                <i className='fas fa-redo'></i>&nbsp;
                <FormattedMessage {...errorMessages['error.btn.retry']} />
            </Button>
        </div>
    )
}