//script to detect if there is avif support in the webview, source: https://stackoverflow.com/questions/5573096/detecting-webp-support
export function supportFormatAvif(): boolean {
  const elem = document.createElement('canvas')

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/avif').indexOf('data:image/avif') == 0
  } else {
      // very old browser like IE 8, canvas not supported
      return false
  }
}