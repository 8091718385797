import Button from '@/shared/components/Button/Button'
import { getClubUrl } from '@/shared/utils/getClubUrl'
import { useClub } from 'contexts/club'
import { useRouter } from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { errorMessages } from './const'


function ErrorLinkToGoBack(): JSX.Element {
    const router = useRouter()
    const { club } = useClub()
    const goBack = () => {
        if(document.referrer === '' && club){
            const url = getClubUrl(club)
            window.location.href = `${url}/home`
        } else if(!club) {
            window.location.href = process.env.NEXT_PUBLIC_APP_WEBSITE;
        } else {
            router.back()
        }
    }
    return (
        <div>
            <Button
                mods='green|cta'
                type={'button'}
                onClick={goBack}
            >
                <i className='fas fa-arrow-left'></i>&nbsp;
                <FormattedMessage {...errorMessages['error.go_back']} />
            </Button>
        </div>
    )
}

export default ErrorLinkToGoBack

